import React from 'react';
import { Table, List, ThemeIcon, Spoiler } from '@mantine/core';
import { CircleCheck } from 'tabler-icons-react';
import Layout from '../../components/Layout';
import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';
import Tabs from '../../components/Tabs';
import adebayo from '../../assets/images/allied-departments/physiotherapy/adebayo.png';
import adedipe from '../../assets/images/allied-departments/physiotherapy/adedipe.jpg';
import adegoke from '../../assets/images/allied-departments/physiotherapy/adegoke.jpg';
import akinbo from '../../assets/images/allied-departments/physiotherapy/akinbo.jpg';
import chizoba from '../../assets/images/allied-departments/physiotherapy/chizoba.jpg';
import eluemuno from '../../assets/images/allied-departments/physiotherapy/eluemuno.png';
import gboyega from '../../assets/images/allied-departments/physiotherapy/gboyega.jpg';
import olaniyan from '../../assets/images/allied-departments/physiotherapy/olaniyan.png';
import omotola from '../../assets/images/allied-departments/physiotherapy/omotola.png';
import sunday from '../../assets/images/allied-departments/physiotherapy/sunday.jpg';
import rotimi from '../../assets/images/allied-departments/physiotherapy/rotimi.jpg';
import team1 from '../../assets/images/team-1-1.jpg';

const anaesthesia = () => {
  const staffs = [
    {
      name: 'Mr. Oladimeji Ayobami Adetunji',
    },
    {
      name: 'Miss. Kafayat Olayemi Jinadu',
    },
    {
      name: 'Mr. Chibuike Bright Ibeh',
    },
  ];

  const services = [
    {
      name:
        'General Anaesthesia: This forms the majority of our services for elective and emergency procedures. ',
    },
    {
      name:
        'Regional Anaesthesia comprising of Subarachnoid block (Spinal Anaesthesia), Epidural block, Combined Spinal Epidural (CSE), Caudal block, Pediatric epidural, Biers block. They are performed as sole anaesthetic techniques or as part of a general anaesthetic.',
    },
    { name: 'Obstetric analgesia service for high-risk parturient.' },
    {
      name:
        'Monitored Anaesthesia Care (MAC) is usually deployed to patients who do not require anaesthesia but need close monitoring during the procedure e.g. pace maker insertion, drainage of subdural haematoma, tracheostomy etc.  ',
    },

    {
      name:
        'Procedural Sedation Analgesia (Conscious Sedation) usually for children that require some form of intervention for diagnostic or therapeutic purposes e.g. intrathecal injection of chemotherapy agents, bone marrow aspiration, radiological and radiotherapy procedures ',
    },
    {
      name:
        'Critical Care Medicine is provided in a 4-bed well-equipped Intensive Care Unit which provides ventilatory and circulatory support as well as critical care interventions.',
    },
    { name: 'Transport and transfer of critically ill patients' },
    {
      name:
        'Resuscitation: We are the hospital’s team lead in cardiopulmonary resuscitation (CPR) and provide service to ALL areas of the hospital.',
    },
  ];

  const nonRows = staffs.map((element) => (
    <tr key={element.name}>
      <td>{element.name}</td>
    </tr>
  ));
  return (
    <Layout pageTitle='Physiotherapy Department | LUTH'>
      <NavOne />
      <PageHeader title='Physiotherapy Department' />
      <section className='course-details'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='course-details__content'>
                <div className='course-details__top'>
                  <div className='course-details__top-left'>
                    <h2 className='course-details__title'>
                      Physiotherapy Department
                    </h2>
                  </div>
                </div>
                <Tabs>
                  <div label='Overview'>
                    <Spoiler
                      maxHeight={300}
                      hideLabel='Hide'
                      showLabel='Show More'
                    >
                      <p className='course-details__tab-text'>
                        Physiotherapy services started at Lagos University
                        Teaching Hospital as a unit under Paediatric Department,
                        It later became a full department in 1968 with Mr.G.I
                        Odia ( Later Professor Odia ) the only physiotherapist
                        member of staff and pioneer head of Department. The
                        Department has since been the training facility for
                        students of the College of Medicine, University of
                        Lagos, and students on clinical attachment and rotation
                        in physiotherapy from other institutions within and
                        outside the country.
                        <br />
                        <br />
                        {/* <h2
                          className='blog-one__title'
                          style={{ marginTop: 2 }}
                        >
                          Training in the Department
                        </h2>
                        The department is involved in training of undergraduate
                        medical and dental students in resuscitation, safe
                        anaesthesia techniques and critical care medicine. There
                        are presently two postgraduate programmes run in the
                        department; Diploma in Anaesthesia of the University of
                        Lagos (PGDA) and the Residency Training Programmes for
                        the Fellowship of the National Postgraduate Medical
                        College (FMCA) and the West African College of Surgeons
                        (FWACS). The Department has been in the forefront of
                        postgraduate anaesthesia training being the first
                        Department in the country to offer the University
                        Postgraduate Diploma in Anaesthesia (PGDA) thereby
                        contributing to the provision of middle-level manpower.
                        The School of Anaesthetic Technician (SOAT) runs a 3
                        year training course culminating in a Diploma
                        (Anaesthesia technician). Presently there are 25
                        students enrolled. Coordinator: Dr. Adeniyi Adesida
                        Deputy Coordinator: Dr. Pamela Agbamu Our departmental
                        staff is also involved in the training of Theatre Nurses
                        and Midwives, Accidents and Emergency Nurses and
                        Ophthalmic Nurses. We organize training on
                        Cardiopulmonary Resuscitation (CPR) for hospital staff
                        as well as corporate bodies and Institutions. In 2017,
                        the Department was accredited as a training center for a
                        post-fellowship training programme in paediatric
                        anaesthesia by the World Federation of Societies of
                        Anaesthesiologist (WFSA). The Department of Anaesthesia
                        is committed to maintaining excellence in anaesthesia
                        through quality education, service and research.
                        <br />
                        <br />
                        <h2
                          className='blog-one__title'
                          style={{ marginTop: 2 }}
                        >
                          THEATRE COMPLEXES
                        </h2>
                        <b>Modular Theatre:</b> The main theatre complex is the
                        modular theatre complex which has 6 suites that are
                        functional and well equipped as well as a recovery room
                        or post anaesthetic care room which can accommodate more
                        than 10 patients . <br /> Obstetric theatre: situated in
                        the newly renovated obstetric complex comprising of 2
                        theatre suites. <br /> Obstetric Anaesthesia
                        Coordinator: Dr. Pamela Agbamu <br />
                        Deputy:Dr. Uzoma Ifezue <br />
                        <br />
                        <b>KidsOR/ SmileTrain theatre:</b> is situated on the
                        1st floor of the Olikoye Ransome Kuti Paediatric
                        complex. It comprises of 2 theatre suites which are
                        exclusively for children. <br />
                        <br />
                        <b>Ophthalmic Theatre:</b> is situated on the 1st floor
                        of Guinness Eye Centre. It consists of 2 operating
                        theatres dedicated to eye surgeries. */}
                      </p>
                    </Spoiler>
                  </div>
                  <div label='Services'>
                    <div label='Overview'>
                      <Spoiler
                        maxHeight={300}
                        hideLabel='Hide'
                        showLabel='Show More'
                      >
                        <p className='course-details__tab-text'>
                          The department provides cutting edge physiotherapy
                          services hinged on evidence-based practice and
                          patient-centred care in a multi-disciplinary setting
                          to the teeming clients/patients in the hospital on
                          both in- and out-patient basis. Our highly skilled and
                          trained physiotherapists provide physiotherapy
                          specialty and subspecialty services in the following
                          areas: Neurology and mental health physiotherapy,
                          orthopaedic/sports Physiotherapy, Cardiopulmonary
                          physiotherapy, Paediatrics physiotherapy,
                          Community-based/ palliative care, Women Health,
                          Ergonomic and occupational health physiotherapy,
                          Geriatrics physiotherapy.
                          <br />
                          <br />
                        </p>
                      </Spoiler>
                    </div>
                  </div>
                  <div label='Staff'>
                    <React.Fragment>
                      <h2 className='blog-one__title'>Staff Details</h2>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={gboyega} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Prof. Gboyega A. Awe </h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            B.Sc, M.Sc MNSP
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Director /Head, Physiotherapist 
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Cardiopulmonary 
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={akinbo} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>PROF. Sunday AKINBO</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            PhD, FWSPC, FASI
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Honorary Consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Musculoskeletal/ Orthopedics & Sports Physiotherapist
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>PROF. Olajide  Ayinla OLAWALE</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            PhD.
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Honorary Consultant
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Neurology /Mental Health
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={olaniyan} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Akinlade Sola OLANIYAN </h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            BMR/PT, M.Sc,MPhil
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Deputy Director, Physiotherapy Services
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Neurology/Mental Health Physiotherapy
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={adedipe} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Adedipe Olanipekun  ADAMSON</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            B.Sc, M.Sc ,MNSP
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Deputy Dircetor, Physiotherapy Services 
                            <br />
                            <span className='detail-head'>Specialty:</span> Paediatrics
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={adegoke} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Adegoke  Moyinoluwa AKINFELEYE</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            B.sc PT, M.Sc. PT, M.Sc. PH (EPID), MNSP 
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Deputy Director, Physiotherapy Service
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Acute Care & Cardiopiratory
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={rotimi} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Rotimi Olanrewaju KARREM </h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            PhD; Asc. FIHTC(oxfd)
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Assistant Director,Physiotherapy Services
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Musculoskeletal/Orthopaedics, Sports & Recreational Physiotherapy.
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={sunday} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Sunday Oluwaseun OLAPADE </h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            B. PT, M.Sc. PT, IFSPT, MNSP
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Chief Physiotherapist
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Neurology/Mental Health 
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={eluemuno} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Eluemuno Lauretta NDIONUKA</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            B.Sc, M.Sc, MNSP.
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Chief Physiotherapy
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Neurology/Women Health
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={omotola} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Omotola Gbemi SOTADE</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MMR/PT
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Physiotherapist
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Musculoskeletal /Orthopaedics &Sports Physiotherapy
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={chizoba} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Mrs Chizoba IGWE</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            BMR/PT, MSc PT
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Chief Physiotherapist
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Musculoskeletal/Orthopedics
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={adebayo} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Adebayo Owanike Shakirat </h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            BMR(PT)(Ife)
                            <br />
                            <span className='detail-head'>
                              Designation:
                            </span>{' '}
                            Physiotherapist 
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Neurology/Paediatrics
                          </p>
                        </div>
                      </div>
                      
                     
                    </React.Fragment>
                  </div>
                  <div label='Contact'>
                    <React.Fragment>
                      <h2 className='blog-one__title'>Departmental Email</h2>
                      <p className='course-details__tab-text'>
                        <a href='mailto:anaesthesia@luth.gov.ng'>
                        physiotherapy@luth.gov.ng
                        </a>
                      </p>
                    </React.Fragment>
                  </div>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  );
};

export default anaesthesia;
